import React, { useState } from 'react';
import { Formik } from "formik";
import { Form } from 'react-bootstrap';
import http from '../_api/http';

const Contact = () => {

  const [values, setValues] = useState({
    fullname:'',
    email: '',
    phone_number: '',
    message:''
  });

  const [validations, setValidations] = useState({
    fullname: '',
    email: '',
    phone_number: '',
    message:''
  });

  const validateAll = () => {
    const { fullname, email,phone_number, message } = values
    const validations = { fullname: '', email: '', phone_number: '', message: '' }
    let isValid = true ;
    
    if (!fullname) {
      validations.fullname = 'Fullname is required'
      isValid = false
    }
    
    if (!email) {
      validations.email = 'Email is required'
      isValid = false
    }
    
    if (email && !/\S+@\S+\.\S+/.test(email)) {
      validations.email = 'Email format must be as example@mail.com'
      isValid = false
    }
    
    if (!phone_number) {
      validations.phone_number = 'Phone Number is required'
      isValid = false
    }

    if (phone_number && !/^[0-9]{10}$/.test(phone_number)) {
      validations.phone_number = 'Phone Number must contain 10 digits'
      isValid = false
    }

    if (!message) {
      validations.message = 'Message is required'
      isValid = false
    };
    
    
    if (!isValid) {
      setValidations(validations);
    }
    
    return isValid ;
  }

  const handleChange = (e) => {
    const { name, value } = e.target ;
    setValues({...values, [name]: value });
  }

  const clearData = () => {
    setValues({
    fullname: '',
    email: '',
    phone_number: '',
    message:''
    });
  }
  
  // const showTost = (msg, theme) => {
  //   theme = theme || "info";
  //   immediateToast(theme, {
  //     message: msg,
  //     timeout: 3000
  //   });
  // }

  const handleSubmit = (e) => {
    e.preventDefault();

    const isValid = validateAll() ;
    
    if (!isValid) {
      return false ;
    }
    http.post('api/inquire/store',values).then((res)=>{
       console.log(res);
      alert("Succefully");
      clearData();
    })

    // alert(JSON.stringify(values))
  }

  const { fullname, email,phone_number, message} = values ;

  const validateOne = (e) => {
    const { name } = e.target ;
    const regex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
    const value = values[name] ;
    let message = '' ;
    
    if (!value) {
      message = `${name} is required`
    }
    
    if (value && name === 'phone_number' && !/[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(value)) {
      message = 'Phone Number must contain 10 number digits'
    }

    if (value && name === 'email' && !/\S+@\S+\.\S+/.test(value)) {
      message = 'Email format must be as example@mail.com'
    }
    
    setValidations({...validations, [name]: message });
  }
  
  const { 
    fullname: fullnameVal, 
    email: emailVal, 
    phone_number: numberVal,
    message: messageVal,
  } = validations ;
  return (
    <div>
      <section className='contact-info-one'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4'>
              <div className='contact-info-one__single'>
                <div className='contact-info-one__icon'>
                  <i className='kipso-icon-manager'></i>
                </div>
                <h2 className='contact-info-one__title'>
                  School visiting hours
                </h2>
                <p className='contact-info-one__text'>
                  The Shanti Asiatic School(SAS)
                  <br /> visiting time
                  <br />
                   8:00 am to 4:00 pm.
                </p>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='contact-info-one__single'>
                <div className='contact-info-one__icon'>
                  <i className='kipso-icon-placeholder'></i>
                </div>
                <h2 className='contact-info-one__title'>Our Address</h2>
                <p className='contact-info-one__text'>
                  Block No. 421-427, Olpad-Dandi Road, <br />
                  New Narthan Town, Taluka: Olpad, <br /> District : Surat 395
                  005
                </p>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='contact-info-one__single'>
                <div className='contact-info-one__icon'>
                  <i className='kipso-icon-contact'></i>
                </div>
                <h2 className='contact-info-one__title'>Contact Info</h2>
                <p className='contact-info-one__text'>
                  info@surat.shantiasiatic.com <br />
                  +91 7567400000 <br /> &nbsp;
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='contact-one dup'>
        <div className='container'>
          <h2 className='contact-one__title text-center'>
            Get in touch <br />
            with us
          </h2>
          <Formik>
                <Form
                  id='contactform'
                  onSubmit={handleSubmit}
                  onBlur={validateOne}
                >
                  <div className='row low-gutters'>
                    <div className='col-lg-12'>
                      <input
                        type='text'
                        name='fullname'
                        placeholder='Your Full Name'
                        value={fullname} 
                        onChange={handleChange}
                        autoComplete='off'
                        // onBlur={validateOne}
                      />
                        <div className='error'>{fullnameVal}</div>
                    </div>

                    <div className='col-lg-6'>
                      <input
                        type='email'
                        placeholder='Your Email Address'
                        name='email'
                        value={email}
                        onChange={handleChange} 
                        // onBlur={validateOne}
                        autoComplete='off'
                      />
                      <div className='error'>{emailVal}</div>
                    </div>
                    

                    <div className='col-lg-6'>
                      <input
                        type='number'
                        placeholder='Your Phone number'
                        name='phone_number'
                        autoComplete='off'
                        value={phone_number} 
                        onChange={handleChange}
                        // onBlur={validateOne}
                      />
                      <div className='error'>{numberVal}</div>
                    </div>
                    
                    
                    <div className='col-lg-12'>

                      <textarea
                        placeholder='Give us more details..'
                        name='message'
                        autoComplete='off'
                        value={message}
                        onChange={handleChange}
                        // onBlur={validateOne}
                      ></textarea>
                      <div className='error'>{messageVal}</div>
                      <div className='text-center'>
                        <button type='submit' className='contact-one__btn thm-btn'>Contact Us</button>
                        {/* <Link
                          onClick={handleSubmit}
                          className='contact-one__btn thm-btn'
                        >
                          Contact Us
                        </Link> */}
                      </div>
                    </div>
                    
                  </div>
                </Form>
                </Formik>
          <div className='result text-center'></div>
        </div>
      </section>
      <iframe
        src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d59489.84160998546!2d72.724916!3d21.266911!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89820fe18c92ec7!2sShanti%20Asiatic%20School!5e0!3m2!1sen!2sin!4v1668158842537!5m2!1sen!2sin'
        className='google-map__contact'
        title='template google map'
        allowFullScreen=''
      ></iframe>
    </div>
  )
}

export default Contact
